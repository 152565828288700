import { CheckCircleRounded } from "@mui/icons-material";
import { FormControlLabel, Tooltip } from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import { MinusCircle } from "react-feather";
import IOSSwitch from "src/components/IOSSwitch";

const { DateTime } = require("luxon");

const dateFormat = (isoDate) => {
  return format(parseISO(isoDate), "dd-MM-yyyy");
  return DateTime.fromISO(isoDate).toLocaleString({
    ...DateTime.DATE_SHORT,
    month: "long",
  });
};

const fileName = (path) => {
  return path.split("/").pop();
};

const dateTimeFormat = (isoDate) => {
  return format(parseISO(isoDate), "dd-MM-yyyy hh:mm a");
};

const dateFormatNumber = (isoDate) => {
  return DateTime.fromISO(isoDate).toLocaleString({
    ...DateTime.DATE_SHORT,
    month: "short",
  });
};

const boolIcons = (b, color) => {
  if (b) {
    return <CheckCircleRounded style={{ color: color ? color : "green" }} />;
  } else {
    return <MinusCircle style={{ color: color ? color : "red" }} />;
  }
};

/**
 *
 * @param {*} param0
 * @returns
 */
const showCustomerCurrencies = ({
  value,
  customer,
  defaultOnly,
  Component,
}) => {
  const v = numberFormat({ value: value, decimalPlaces: 2 });
  const { globals } = customer;

  if (globals.currencies) {
    var defaultCurrency = (
      <tr key={`default-currency`}>
        <td style={{ textAlign: "right", fontSize: "0.85rem" }}>
          <Tooltip title={globals.currencies[0].name_plural} arrow>
            <span>
              <strong>{`${globals.currencies[0].code}`}</strong> {`${v}`}
            </span>
          </Tooltip>
        </td>
      </tr>
    );
    if (defaultOnly) {
      if (!Component) {
        Component = "span";
      }
      return (
        <Component>
          {" "}
          <strong>{`${globals.currencies[0].code}`}</strong> {`${v}`}
        </Component>
      );
    }
    var currencies = [defaultCurrency];
    globals.currencies.map((c) => {
      if (!c.is_default && c.display) {
        currencies.push(
          <tr key={`${c.id}`}>
            <td style={{ fontSize: "0.65rem", textAlign: "right" }}>
              <Tooltip title={c.name_plural} arrow>
                <span>
                  <strong>{c.code}</strong>{" "}
                  {numberFormat({
                    value: value * c.exchange_rate,
                    decimalPlaces: c.decimal_places,
                  })}
                </span>
              </Tooltip>
            </td>
          </tr>
        );
      }
    });
    return (
      <table style={{ width: "100%" }}>
        <tbody>{currencies}</tbody>
      </table>
    );
  } else {
    return numberFormat({ value: value });
  }
};
const numberFormat = ({ value, notation, decimalPlaces }) => {
  const n = notation ? notation : "standard";
  const nFormat = new Intl.NumberFormat("es-VE", {
    notation: n,
    maximumFractionDigits: decimalPlaces ? decimalPlaces : 2,
    minimumFractionDigits: 2,
  });
  return nFormat.format(parseFloat(value) || 0);
};
numberFormat.defaultProps = {
  value: 0,
  notation: "standard",
  decimalPlaces: 2,
};

const addressText = (a) => {
  return `${a.street} ${a.building} ${a.floor} ${a.sector} ${a.parish}`.toLocaleUpperCase();
};
const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const randomInteger = (max) => {
  return Math.floor(Math.random() * (max + 1));
};

const SwitchCheck = ({ label, value, onChange, name }) => {
  return (
    <FormControlLabel
      control={
        <IOSSwitch
          checked={Boolean(value)}
          onChange={onChange}
          name={`${name}`}
        />
      }
      label={`${label}`}
    />
  );
};

const buildTable = ({ columns, data, tableProps }) => {
  const theme = theme;
  var _cellStyles = {
    height: "25px",
    padding: "3px",
    fontSize: "12px",
  };
  var _rowStyles = {
    borderBottom: "1px Solid #ccc",
  };
  var _headerStyle = { borderRadius: "10px" };
  if (theme.palette) {
    _headerStyle = {
      ..._headerStyle,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    };
  }

  return (
    <table
      style={{ width: "100%", ...tableProps }}
      cellspacing="0"
      cellpadding="5"
    >
      <thead>
        <tr
          style={{ ..._rowStyles, ..._headerStyle }}
          sx={{ backgroundColor: "secondary.main" }}
        >
          {columns.map((_c, _j) => {
            return (
              <th
                key={`h-${_j}`}
                style={{ ..._cellStyles, ..._c.style }}
                {..._c.props}
              >
                {_c.label}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((_r, _ri) => {
          if (_r === null) {
            return;
          }
          return (
            <tr key={`r-${_ri}`} style={{ ..._rowStyles }}>
              {data[_ri].map((_d, _j) => {
                return (
                  <td
                    style={{ ..._cellStyles, ...columns[_j].dataStyle }}
                    {...columns[_j].props}
                  >
                    {_d}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const handleDownloadFile = (response, filename) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  if (filename) {
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return;
  }

  if (response.headers && response.headers["x-filename"]) {
    link.setAttribute("download", response.headers["x-filename"]);
    document.body.appendChild(link);
    link.click();
    return;
  }
  if (response.headers && response.headers["content-disposition"]) {
    var filename = response.headers["content-disposition"];
    filename = filename.split(";")[1];
    filename = filename.replace("filename=", "");
    filename = filename.replaceAll('"', "");
    filename = filename.replaceAll(" ", "");
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    return;
  }
  if (response.headers["content-type"] === "application/pdf") {
    link.setAttribute("download", "reporte.pdf");
    document.body.appendChild(link);
    link.click();
    return;
  }
  console.error(
    "No se pudo descargar el archivo, no se encontr� el nombre y no se pudo inferir el tipo de archivo"
  );
  link.setAttribute("download", filename);
  // document.body.appendChild(link);
  // link.click();
};

const getObjectElement = (item, n) => {
  var nParts = n.split(".");
  var v = item[nParts[0]];

  nParts.forEach((part, i) => {
    if (i === 0) {
      return;
    }
    try {
      v = v[part];
    } catch (e) {
      // console.log(n, item, v, part);
    }
  });

  return v;
};

const defaultViewStates = {
  add: "ADD",
  edit: "EDIT",
  invalidID: "INVALID_ID",
  delete: "DELETE",
  notFound: "NOT_FOUND",
  internalError: "INTERNAL_ERROR",
  printing: "PRINTING",
  downloading: "DOWNLOADING",
  loading: "LOADING",
  attach: "ATTACH",
};
const defaultErrorStates = [
  defaultViewStates.invalidID,
  defaultViewStates.notFound,
  defaultViewStates.internalError,
];

export {
  dateFormat,
  dateTimeFormat,
  dateFormatNumber,
  boolIcons,
  SwitchCheck,
  numberFormat,
  addressText,
  showCustomerCurrencies,
  buildTable,
  handleDownloadFile,
  getObjectElement,
  defaultViewStates,
  defaultErrorStates,
  fileName,
};
