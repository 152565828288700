import { createSlice } from "@reduxjs/toolkit";
import arkadu from "src/coreConfig";
import { default as axios, default as axiosInstance } from "src/utils/axios";
const initialState = {
  documentTypes: [
    { id: "property", name: "Titulo de propiedad" },
    { id: "register", name: "Documento registrado" },
    { id: "notarized", name: "Documento notariado" },
    { id: "inspection", name: "Inspecci�n Legal" },
    { id: "tech_inspection", name: "Inspecci�n t�cnica" },
    { id: "authorization", name: "Autorizaci�n legal" },
    { id: "origin", name: "Certificado de origen" },
  ],
  inscription: null,
  fuelTypes: [],
  bodyTypes: [],
  transmissionsTypes: [],
  vehicleTypes: [],
  manufactures: [],
  vehicleUses: [],
  userVehicles: [],
  customerVehicles: [],
  motorError: null,
  motorLoaded: false,
  currentVehicle: null,
  motorInitialized: false,
  motorFilters: {
    approved: "",
  },
};

const slice = createSlice({
  name: "motor",
  initialState,
  reducers: {
    getVehicleOptions: (state, action) => {
      const {
        countries,
        fuelTypes,
        bodyTypes,
        transmissionsTypes,
        vehicleTypes,
        manufactures,
        vehicleUses,
      } = action.payload;
      // motorInitialized= false;
      // motorFilters = {
      //   approved: "",
      // };
      state.countries = countries;
      state.fuelTypes = fuelTypes;
      state.bodyTypes = bodyTypes;
      state.transmissionsTypes = transmissionsTypes;
      state.vehicleTypes = vehicleTypes;
      //   state.manufactures = manufactures;
      state.vehicleUses = vehicleUses;
      state.motorLoaded = true;
      state.motorInitialized = true;
      
    },
    setManufacturers: (state, action) => {
      state.manufactures = action.payload;
    },
    setCurrentVehicle: (state, action) => {
      state.currentVehicle = action.payload;
    },
    setMotorError: (state, action) => {
      state.motorError = action.payload;
    },
    setInscription: (state, action) => {
      state.inscription = action.payload;
    },
    setMotorItems: (state, action) => {
      Object.keys(action.payload).forEach((k) => {
        state[k] = action.payload[k];
      });
    },
  },
});

export const { setMotorItems } = slice.actions;

export const reducer = slice.reducer;

export const getMotorOptions = (data) => async (dispatch) => {
  // var storeMotor = localStorage.getItem("motors");
  var storeMotor = null;

  if (storeMotor) {
    dispatch(slice.actions.getVehicleOptions(JSON.parse(storeMotor)));
  } else {
    const response = await axiosInstance.post(
      arkadu.urls.v2.motor.options,
      data
    );
    localStorage.setItem("motors", JSON.stringify(response.data.data));
    dispatch(slice.actions.getVehicleOptions(response.data.data));
  }
};

export const searchManufactures = (data) => async (dispatch) => {
  var response = await axiosInstance.post(arkadu.urls.v2.motor.options, {
    action: "search_manufacture",
    ...data,
  });

  dispatch(slice.actions.setManufacturers(response.data.data));
};
export const setManufacturers = (data) => async (dispatch) => {
  dispatch(slice.actions.setManufacturers(data));
};
export const setInscription = (data) => async (dispatch) => {
  dispatch(slice.actions.setInscription(data));
};
export const addManufacturer = (data) => async (dispatch) => {
  var response = await axiosInstance.post(arkadu.urls.v2.motor.options, {
    ...data,
    action: "add_manufacturer",
  });

  dispatch(slice.actions.setManufacturers([response.data.data]));
};

export const setCurrentVehicle = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentVehicle(data));
};

export const getVehicleDescription = (v) => {
  return `#${v.id} ${v.model_name} ${
    v.build_year
  } ${v.plate_number.toUpperCase()}`;
};

export const getDocType = (doctype) => {
  var _t = "";
  initialState.documentTypes.forEach((t) => {
    if (t.id === doctype) {
      _t = t.name;
    }
  });
  return _t;
};
export const addNewVehicleDocument = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.motor.vehicle, data);

  try {
    dispatch(slice.actions.setCurrentVehicle(response.data.data));
  } catch (err) {
    dispatch(slice.actions.setMotorError(err.message));
  }
};

export const getVehicleInscription = (data) => async (dispatch) => {
  var response = await axios.get(arkadu.urls.v2.motor.inscription_detail, {
    params: data,
  });
  if (response.data.res === 1) {
    dispatch(slice.actions.setInscription(response.data.data));
  }
};
