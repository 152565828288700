//@ts-check
import {
  AccountCircleRounded,
  MoreVertRounded,
  PhotoCamera,
  RefreshRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createTheme,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AvatarUploader from "src/components/AvatarUploader";
import MultiSelectList from "src/components/custom/MultiSelectList";
import Dialogs from "src/components/Dialogs";
import arkadu, { media_url } from "src/coreConfig";
import useAsyncRequest from "src/hooks/useAsyncRequest";
import { useDebitsWithTempBalance } from "src/hooks/useUserAccount";
import { setShopState } from "src/slices/shop";
import { checkPermission } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import {
  defaultViewStates,
  showCustomerCurrencies,
} from "src/utils/dataRenders";

const theme = createTheme();

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "left",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    "&:hover": {
      boxShadow: theme.shadows[2],
    },
  },
  cardAccount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    width: "450px",
    [theme.breakpoints.down("md")]: {
      width: "350px",
    },
    // [theme.breakpoints.up("md")]: {
    //   width: "500px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   width: "700px",
    // },
    // [theme.breakpoints.up("xl")]: {
    //   width: "900px",
    // },

    // padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease",
    backgroundColor: theme.palette.background.paper,
    // cursor: "pointer",
    boxShadow: theme.shadows[1],
    "&:hover": {
      // transform: "translateY(-5px)",
      boxShadow: theme.shadows[2],
    },
  },
  cardAccountHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  accountHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  accountIdentification: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    // padding: theme.spacing(2),
    "& > *": {
      margin: theme.spacing(0.5),
      lineHeight: 0.75,
    },
  },

  avatar: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(8),
    height: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  name: {},
  email: {
    color: theme.palette.text.secondary,
  },
  sectionLabel: {
    color: theme.palette.text.secondary,
  },
  balanceWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),

    backgroundColor: "#f5f5f5",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    width: "100% !important",
    "&:parent": {
      width: "100%",
    },
  },
  alignRight: {
    textAlign: "right",
  },
  balance: {
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  negativeBalance: {
    color: theme.palette.error.main,
  },
  positiveBalance: {
    color: theme.palette.success.main,
  },
  identity: {},
});

const viewStates = {
  ...defaultViewStates,
  updateAvatar: "updateAvatar",
};
const AccountCard = ({ variant }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { currentAccount } = useSelector((state) => state.shop);

  const { user } = useSelector((state) => state.users);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);
  const [state, setState] = useState(null);
  const { api } = useAsyncRequest();
  const dispatch = useDispatch();

  const reloadAccount = () => {
    dispatch(setShopState({ reloadRequest: true }));
  };

  const canDo = (action, module) => {
    if (!module) {
      module = "shopping_cart";
    }
    return (
      adminMode &&
      checkPermission({
        action,
        module,
        customerId: currentCustomer.id,
        user: user,
      })
    );
  };

  const handleViewUser = () => {
    window.open(
      `/${currentCustomer.shortname}/admin/user/${currentAccount.owner.id}/`,
      "_blank"
    );
  };

  const handleUpdateAvatar = async (f, filename) => {
    // f is base64 image
    const fileBlob = await fetch(f).then((r) => r.blob());

    setState(null);

    var form = new FormData();
    form.append("avatar", fileBlob, filename);
    form.append("id", currentAccount.id);
    form.append("customer_id", currentCustomer.id);
    console.log(form);
    var res = await api(
      arkadu.urls.v2.account.updateAvatar,
      form,
      {
        ContentType: "multipart/form-data",
      },
      t("Actualizando avatar")
    );
    if (res.status === 200) {
      reloadAccount();
    }
  };
  if (variant === "listItem") {
    // Return a ListItem
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatar} src={currentAccount.avatar} />
        </ListItemAvatar>
        <ListItemText
          primary={currentAccount.name}
          secondary={currentAccount.email}
        />
      </ListItem>
    );
  }

  return (
    <>
      <Dialogs
        value={state}
        onClose={() => setState(null)}
        dialogs={[
          {
            value: viewStates.updateAvatar,
            title: t("Personalizar cuenta"),
            size: "lg",
            body: (
              <>
                <AvatarUploader
                  aspect={1}
                  onSelect={(f, filename) => {
                    handleUpdateAvatar(f, filename);
                  }}
                />
              </>
            ),
            actions: [],
          },
        ]}
      />
      <AccountCardItem
        account={currentAccount}
        actions={[
          {
            icon: <RefreshRounded color="success" />,
            label: t("Actualizar"),
            onClick: reloadAccount,
            color: "success",
          },
          {
            icon: <PhotoCamera color="success" />,
            label: t("Cambiar avatar"),
            color: "success",
            onClick: () => setState(viewStates.updateAvatar),
          },
          {
            icon: <AccountCircleRounded color="info" />,
            label: t("Ver usuario"),
            onClick: handleViewUser,
            color: "primary",
          },
        ]}
        currentCustomer={currentCustomer}
      />
    </>
  );
};

AccountCard.defaultProps = {
  variant: "card",
};

const AccountCardById = ({ accountId, variant }) => {
  const { api } = useAsyncRequest();
  const { t } = useTranslation();
  const { currentCustomer } = useSelector((state) => state.customers);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAccount = async () => {
      setLoading(true);
      var res = await api(
        arkadu.urls.v2.account.detail,
        { id: accountId, customer_id: currentCustomer.id },
        {},
        t("Cargando cuenta")
      );
      if (res.data.res === 1) {
        setAccount(res.data.data);
        console.log(res.data.data);
      }
      setLoading(false);
    };
    getAccount();
  }, [accountId, currentCustomer]);

  if (account === null) {
    return null;
  }

  return (
    <AccountCardItem account={account} currentCustomer={currentCustomer} />
  );
};

AccountCardById.propTypes = {
  accountId: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(["card", "listItem"]),
};

const AccountCardItem = ({
  account,
  currentCustomer,
  selectTransactions,
  selectFilter,
  onSelect,
  actions,
  mode,
}) => {
  const classes = useStyle();
  const { pendingTransactions } = useDebitsWithTempBalance(
    account.debits || []
  );
  const accountCardViewStates = {
    showSelect: "showSelect",
  };
  const [accountCardState, setAccountCardState] = useState({
    moreOpen: false,
    selectOpen: false,
    refresh: false,
    anchorEl: null,
    selectedTransactions: [],
    viewState: null,
    pendingTransactions: [],
  });

  const handleSelectTransactions = (e) => {
    // append or remove transaction from selectedTransactions
    const transactionId = e.target.value;
    const selectedTransactions = accountCardState.selectedTransactions;
    if (selectedTransactions.includes(transactionId)) {
      setAccountCardState({
        ...accountCardState,
        selectedTransactions: selectedTransactions.filter(
          (t) => t !== transactionId
        ),
      });
    }
    if (!selectedTransactions.includes(transactionId)) {
      setAccountCardState({
        ...accountCardState,
        selectedTransactions: [...selectedTransactions, transactionId],
      });
    }
  };

  const handleMenuClose = () => {
    setAccountCardState({
      ...accountCardState,
      moreOpen: false,
      anchorEl: null,
    });
  };

  const { t } = useTranslation();

  useEffect(() => {
    setAccountCardState({
      ...accountCardState,
      pendingTransactions: pendingTransactions,
    });
    console.log(pendingTransactions);
  }, [pendingTransactions]);

  if (!account) {
    return null;
  }

  if (mode === "compact") {
    return (
      <div className={classes.balanceWrapper} style={{maxWidth: "sm"}}>
        <div className={classes.identity}>
          <Typography variant="caption" className={classes.sectionLabel}>
            {t("Datos del Titular")}
          </Typography>
          <Typography className={classes.name} variant="body2">
            {`${account.identity.short_id} ${account.identity.legal_name}`}
          </Typography>
        </div>
        <div className={classes.email}>
          <Typography variant="caption">{account.owner.email}</Typography>
        </div>

        <div className={clsx([classes.alignRight])}>
          <Typography
            variant="h6"
            className={clsx(
              classes.balance,
              account.total < 0
                ? classes.negativeBalance
                : classes.positiveBalance
            )}
          >
            {t("Saldo")}{" "}
            {showCustomerCurrencies({
              value: account.total,
              customer: currentCustomer,
              defaultOnly: true,
            })}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              color: theme.palette.info.main,

              fontWeight: "bold",
            }}
          >
            {t("Por aprobar")}{" "}
            {showCustomerCurrencies({
              value: account.pendingBalance,
              customer: currentCustomer,
              defaultOnly: true,
            })}
          </Typography>
          {account.debitsInactiveSum !== 0 && (
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.error.main,
                fontWeight: "bold",
                paddingLeft: "1rem",
              }}
            >
              {t("Deudas")}{" "}
              {showCustomerCurrencies({
                value: account.debitsInactiveSum,
                customer: currentCustomer,
                defaultOnly: true,
              })}
            </Typography>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <Dialogs
        value={accountCardState.viewState}
        onClose={() =>
          setAccountCardState({ ...accountCardState, viewState: null })
        }
        dialogs={[
          {
            value: accountCardViewStates.showSelect,
            title: t("Seleccionar transacciones"),
            body: (
              <div className={classes.selectTransactions}>
                <MultiSelectList
                  conditionalInOrder
                  items={pendingTransactions.reverse().map((tr) => ({
                    id: tr.id,
                    label: `${tr.id} - ${tr.description} - ${tr.amount}`,
                  }))}
                  onComplete={(selectedTransactions) => {
                    var subTotal = 0;
                    var selectedIds = selectedTransactions.map((t) => t.id);
                    pendingTransactions.forEach((t) => {
                      if (selectedIds.includes(t.id)) {
                        subTotal += t.amount;
                      }
                    });
                    onSelect(selectedTransactions, subTotal);
                    setAccountCardState({
                      ...accountCardState,
                      viewState: null,
                    });
                  }}
                  columns={[
                    {
                      name: "id",
                      label: t("ID"),
                      type: "text",
                    },
                    {
                      name: "description",
                      label: t("Descripci�n"),
                      type: "text",
                    },
                    {
                      name: "amount",
                      label: t("Monto"),
                      type: "currency",
                    },
                  ]}
                />
              </div>
            ),
            actions: [],
          },
        ]}
      />
      <Card className={classes.cardAccount}>
        <CardHeader
          className={classes.cardAccountHeader}
          title={
            <div className={classes.accountIdentification}>
              <Typography variant="caption" className={classes.sectionLabel}>
                {t("N�mero de cuenta")}
              </Typography>
              <Typography variant="body2" className={classes.name}>
                #{account.id}
              </Typography>
            </div>
          }
          avatar={
            <Avatar
              className={classes.avatar}
              src={account.avatar ? media_url(account.avatar) : null}
            />
          }
          action={
            actions && (
              <IconButton
                id={`account-card-${account.id}`}
                aria-expanded={accountCardState.moreOpen ? "true" : undefined}
                aria-label={`account-card-${account.id}`}
                aria-controls={
                  accountCardState.moreOpen
                    ? `account-card-${account.id}-menu`
                    : undefined
                }
                aria-haspopup="true"
                onClick={(e) => {
                  setAccountCardState({
                    ...accountCardState,
                    moreOpen: !accountCardState.moreOpen,
                    anchorEl: e.currentTarget,
                  });
                }}
                color="primary"
              >
                <MoreVertRounded />
              </IconButton>
            )
          }
        />

        <CardContent sx={{ width: "100%" }}>
          <div className={classes.balanceWrapper}>
            <div className={classes.identity}>
              <Typography variant="caption" className={classes.sectionLabel}>
                {t("Datos del Titular")}
              </Typography>
              <Typography className={classes.name} variant="body2">
                {`${account.identity.short_id} ${account.identity.legal_name}`}
              </Typography>
            </div>
            <div className={classes.email}>
              <Typography variant="caption">{account.owner.email}</Typography>
            </div>

            <div className={clsx([classes.alignRight])}>
              <Typography
                variant="h6"
                className={clsx(
                  classes.balance,
                  account.total < 0
                    ? classes.negativeBalance
                    : classes.positiveBalance
                )}
              >
                {t("Saldo")}{" "}
                {showCustomerCurrencies({
                  value: account.total,
                  customer: currentCustomer,
                  defaultOnly: true,
                })}
              </Typography>

              <Typography
                variant="caption"
                sx={{
                  color: theme.palette.info.main,

                  fontWeight: "bold",
                }}
              >
                {t("Por aprobar")}{" "}
                {showCustomerCurrencies({
                  value: account.pendingBalance,
                  customer: currentCustomer,
                  defaultOnly: true,
                })}
              </Typography>
              {account.debitsInactiveSum !== 0 && (
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.error.main,
                    fontWeight: "bold",
                    paddingLeft: "1rem",
                  }}
                >
                  {t("Deudas")}{" "}
                  {showCustomerCurrencies({
                    value: account.debitsInactiveSum,
                    customer: currentCustomer,
                    defaultOnly: true,
                  })}
                </Typography>
              )}
            </div>
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <div className={classes.actions}>
            <ButtonGroup size="small" variant="outlined" color="info">
              {selectTransactions && (
                <Button
                  onClick={() => {
                    setAccountCardState({
                      ...accountCardState,
                      viewState: accountCardViewStates.showSelect,
                    });
                  }}
                >
                  {t("Seleccionar transacciones")}
                </Button>
              )}
            </ButtonGroup>
          </div>
        </CardActions>
      </Card>
      <Menu
        id={`account-card-${account.id}-menu`}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={accountCardState.anchorEl}
        open={accountCardState.moreOpen}
        onClose={(e) => {
          handleMenuClose();
        }}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            // width: "20ch",
          },
        }}
      >
        {actions &&
          actions.map((action, index) => {
            return (
              <MenuItem
                key={index}
                onClick={(e) => {
                  handleMenuClose();
                  if (action.onClick) {
                    action.onClick();
                  }
                }}
              >
                {action.icon && (
                  <ListItemIcon color={action.color}>
                    <Badge badgeContent={action.badge} color={action.color}>
                      {action.icon}
                    </Badge>
                  </ListItemIcon>
                )}
                <ListItemText primary={action.label} />
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

AccountCardItem.propTypes = {
  account: PropTypes.object.isRequired,
  currentCustomer: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string,
      onClick: PropTypes.func,
      color: PropTypes.string,
    })
  ),
  selectTransactions: PropTypes.bool,
  selectFilter: PropTypes.func,
  onSelect: PropTypes.func,
  mode: PropTypes.oneOf(["normal", "compact", null]),
};

export default AccountCard;

export { AccountCardById, AccountCardItem };
