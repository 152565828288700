//@ts-check
import { PersonAdd } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalCase } from "change-case";
import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Settings as SettingsIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { THEMES } from "src/constants";
import { saveSettings, setOpen } from "src/slices/settings";
import { checkPermission } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  // @ts-ignore
  const settings = useSelector((state) => state.settings);
  // @ts-ignore
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  // @ts-ignore
  const { currentCustomer } = useSelector((state) => state.customers);
  const [canAccessAdmin, setCanAccessAdmin] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const initialized = useRef(null);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    adminMode: settings.adminMode,
  });

  const handleOpen = () => {
    dispatch(setOpen(true));
  };

  const handleClose = () => {
    dispatch(setOpen(false));
  };

  const handleChange = (field, value) => {
    var aF = {};
    if (field === "adminMode") {
      aF.adminModeFor = value === true ? currentCustomer.id : null;
    }
    setValues({
      ...values,
      ...aF,
      [field]: value,
    });
    dispatch(
      saveSettings({
        ...values,
        ...aF,
        [field]: value,
      })
    );
    dispatch(setOpen(false));
  };

  const canDo = (action) => {
    return (
      currentCustomer &&
      checkPermission({
        action: action,
        module: "nets_user",
        customerId: currentCustomer.id,
        user: user,
      }) &&
      settings.adminMode
    );
  };

  useEffect(() => {
    if (initialized.current === undefined && currentCustomer && settings) {
      initialized.current = true;
      if (settings.adminModeFor === currentCustomer.id) {
        handleChange("adminMode", true);
      }
    }
    if (
      currentCustomer &&
      user &&
      user.member_set &&
      user.member_set.length > 0
    ) {
      if (_.find(user.member_set, { customer_id: currentCustomer.id })) {
        setCanAccessAdmin(true);
      }
    }
  }, [settings, currentCustomer, user]);

  const handleSave = () => {
    saveSettings(values);
    dispatch(setOpen(false));
  };

  return (
    <>
      <Tooltip title="Settings">
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
            size="large"
          >
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={settings.open}
      >
        <Typography variant="h4" color="textPrimary">
          {t("Configuraci�n")}
        </Typography>
        {/* <Box
        mt={2}
        px={1}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={values.direction === 'rtl'}
              edge="start"
              name="direction"
              onChange={(event) => handleChange('direction', event.target.checked ? 'rtl' : 'ltr')}
            />
          )}
          label="RTL"
        />
      </Box> */}
        <Box mt={2} px={1}>
          {canAccessAdmin && (
            <FormControlLabel
              control={
                <Switch
                  checked={settings.adminMode}
                  edge="start"
                  name="direction"
                  onChange={(event) =>
                    handleChange("adminMode", event.target.checked)
                  }
                />
              }
              label={t("Modo Admin.")}
            />
          )}
        </Box>
        <Box mt={2} px={1}>
          {canDo("can_add_user") && (
            <Button
              onClick={() => {
                window.open(
                  `/${currentCustomer.shortname}/admin/user/registration/?mode=assisted&customer_id=${currentCustomer.id}`,
                  "_blank"
                );
              }}
              startIcon={<PersonAdd />}
              color="info"
              size="small"
            >
              {t("Registrar Usuario")}
            </Button>
          )}
          {/* <FormControlLabel
          control={(
            <Switch
              checked={values.responsiveFontSizes}
              edge="start"
              name="direction"
              onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
            />
          )}
          label="Responsive font sizes"
        /> */}
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label={t("Tema")}
            name="theme"
            onChange={(event) => handleChange("theme", event.target.value)}
            select
            SelectProps={{ native: true }}
            value={settings.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option key={theme} value={theme}>
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        {/* <Box mt={2}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSave}
        >
          {t('Save Settings')}
        </Button>
      </Box> */}
      </Popover>
    </>
  );
};

export default Settings;
