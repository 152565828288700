//@ts-check
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useHistory, useParams } from "react-router";
import arkadu from "src/coreConfig";
import animationData from "src/lotties/real_estate_arkadu_animation";
import { getCadasterConfig } from "src/slices/cadaster";
import { setEventadsState } from "src/slices/eventads";
import { useDispatch, useSelector } from "src/store";
import axios from "src/utils/axios";
import { defaultErrorStates, defaultViewStates } from "src/utils/dataRenders";
import ErrorView from "src/views/errors/ErrorView";

export const CadasterAnimation = ({}) => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRadio: "xMidYMid slice",
        },
      }}
    />
  );
};

const viewStates = {
  ...defaultViewStates,
};
const errorStates = [...defaultErrorStates];
const CadasterLayout = ({ children }) => {
  const { t } = useTranslation();
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.users);
  const { property_types, property_uses, cadasterConfigLoaded } = useSelector(
    (state) => state.cadaster
  );
  const dispatch = useDispatch();
  const initialized = useRef(null);
  const history = useHistory();
  const { id } = useParams();
  const [isSearching, setIsSearching] = useState(false);
  const handleSearch = (searchText) => {};
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [state, setState] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const loadData = async () => {
    dispatch(
      getCadasterConfig({
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      })
    );
    return;
    var i = parseInt(id);
    if (!i) {
      setErrorMessage(t("ID no v�lido"));
      setState(viewStates.invalidID);
      return;
    }
    enqueueSnackbar(t("Cargando data "), { variant: "info" });
    try {
      var res = await axios.post(arkadu.urls.v2.eventads.adRequest.detail, {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        id: i,
      });
      closeSnackbar();
      if (res.data.res === 1) {
        setData(res.data.data);
        dispatch(setEventadsState({ currentAdRequest: res.data.data }));
      } else {
        setErrorMessage(res.data.message || "error");
        enqueueSnackbar(res.data.message || "error", { variant: "error" });
      }
    } catch (e) {
      setErrorMessage((e.data && e.data.message) || e.statusText || "error");
      setState(viewStates.internalError);
      enqueueSnackbar((e.data && e.data.message) || e.statusText || "error", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (initialized.current === null) {
      initialized.current = true;
    }
  }, []);
  useEffect(() => {
    if (currentCustomer) {
      loadData();
    }
  }, [currentCustomer]);

  if (errorStates.includes(state)) {
    return <ErrorView message={errorMessage} />;
  }
  if (!cadasterConfigLoaded) {
    return <CadasterAnimation />;
  }

  return <>{children}</>;
};

export default CadasterLayout;
