import { createSlice } from "@reduxjs/toolkit";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  currentCertId: null,
  certError: null,
  loadingCert: false,
};

const slice = createSlice({
  name: "certificate",
  initialState,
  reducers: {
    setCurrenCertId: (state, action) => {
      state.currentCertId = action.payload;
    },
    setCertError: (state, action) => {
      state.certError = action.payload;
    },
    setLoading: (state, action) => {
      state.loadingCert = action.payload;
    },
    setCurrentCertId: (state, action) => {
      state.currentCertId = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const setCurrentCertId = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrenCertId(data));
};

export const getCertificate = (data) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(slice.actions.setLoading(true));
    dispatch(slice.actions.setCertError(null));
    try {
      var response = await axios.post(arkadu.urls.v2.certificate.verify, data, {
        responseType: "blob",
      });

      if (response.status !== 200) {
        if (response.status === 404) {
          dispatch(
            slice.actions.setCertError(
              "No se encontr� el certificado o ha sido revocado."
            )
          );
          reject("No se encontr� el certificado o ha sido revocado.");
        }
        dispatch(
          slice.actions.setCertError(
            "Error descargando el certificado. Intente nuevamente."
          )
        );
        reject("Error descargando el certificado. Intente nuevamente.");
      }

      const file = new Blob([response.data], {
        type: "application/pdf",
        filename: `CERT_${data.currentCertId}.pdf`,
      });
      const url = window.URL.createObjectURL(file);
      var link = document.createElement("a");
      link.href = url;
      link.download = `ARKADU_CERT_${data.currentCertId}.pdf`;
      link.click();
      //

      dispatch(slice.actions.setLoading(false));
      resolve();
    } catch (err) {
      dispatch(
        slice.actions.setCertError(
          err?.data?.message || err?.statusText || err.message || err || "Error"
        )
      );
      dispatch(slice.actions.setLoading(false));
      reject(err.data.message || err.statusText || err);
    }
  });
};
export default slice;
