import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import arkadu from "src/coreConfig";
import axios from "src/utils/axios";

const initialState = {
  procedures: [],
  departments: [],
  states: [],
  count: 0,
  procedureError: null,
  procedurePreview: null,
  currentProcedure: null,
  currentUserProcedure: null,
  currentDepartment: null,
  loaded: false,
  proceduresLoaded: false,
  socketOpen: false,
  nextToProcedure: null,
  filters: {
    currentPage: 1,
    // department: { value: "ALL", label: t("Todos") },
    // state: { value: procedureStates[0].id, label: procedureStates[0].name },
    order: { label: "Orden de creaci�n", value: "asc" },
    hasAppointment: { label: "Todo", value: "all" },
    paginatedBy: { value: 20, label: "20" },
    accountState: { value: "all", label: "Todos" },
    requirements: { value: "all", label: "Todos" },
    procedure: { value: "all", label: "Todos" },
    page: 1,
    limit: 10,
    search: "",
    department_id: null,
    department: {
      value: "all",
      label: "Todos",
    },
    assignedTo: {
      value: "all",
      label: "Todos",
    },
    account_state: null,
    state: { value: "all", label: "Todos" },
    sort: "createdAt",
    // order: "desc",
  },
  procedureStates: [],
  isLoading: false,
  cleared: false,
};

const slice = createSlice({
  name: "procedures",
  initialState,
  reducers: {
    getProcedures: (state, action) => {
      const { data } = action.payload;
      state.procedures = data.procedures;
      state.count = data.count;
      state.procedureStates = data.states;
      state.departments = data.departments;
      state.loaded = true;
      state.proceduresLoaded = true;
    },
    setCurrentProcedure: (state, action) => {
      const { procedure } = action.payload;
      state.currentProcedure = procedure;
    },
    clearCurrentProcedure: (state, action) => {
      state.currentProcedure = null;
      state.cleared = true;
    },
    updateProcedure: (state, action) => {
      const { procedure } = action.payload;
      state.procedures = _.map(state.procedures, (_p) => {
        if (_p.id === procedure.id) {
          return procedure;
        }
        return _p;
      });
    },

    updateFilters: (state, action) => {
      const { ...news } = action.payload;
      state.filters = { ...state.filters, ...news };
    },
    setCurrentPage: (state, action) => {
      const { page } = action.payload;
      state.currentPage = page;
    },
    deleteProcedure: (state, action) => {
      const { procedure } = action.payload;
      state.procedures = _.reject(state.procedures, { id: procedure.id });
    },
    deleteRequirement: (state, action) => {
      const { requirementId } = action.payload;
      var requirements = _.reject(state.currentProcedure.requirements, {
        id: requirementId,
      });
      var cp = {
        ...state.currentProcedure,
        requirements: requirements,
      };
      state.currentProcedure = cp;
    },
    updateLoadingState: (state, action) => {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
    setProcedureError: (state, action) => {
      const { error } = action.payload;
      state.procedureError = error;
    },
    setCurrentDepartment: (state, action) => {
      const { department } = action.payload;
      state.currentDepartment = department;
    },
    setProcedureState: (state, action) => {
      Object.keys(action.payload).map((k) => (state[k] = action.payload[k]));
    },
  },
});
export const reducer = slice.reducer;

export const setProcedureState = (data) => async (dispatch) => {
  dispatch(slice.actions.setProcedureState(data));
};

export const getProcedures = (data) => async (dispatch) => {
  dispatch(slice.actions.updateLoadingState({ isLoading: true }));

  var response = await axios.post(arkadu.urls.v2.corporate.procedure, data);
  dispatch(slice.actions.updateLoadingState({ isLoading: false }));

  if (response && response.data && response.data.res === 1) {
    dispatch(slice.actions.getProcedures(response.data));
  } else {
    if (response && response.data && response.data.message) {
      dispatch(
        slice.action.setProcedureError({ error: response.data.message })
      );
    } else {
      dispatch(
        slice.action.setProcedureError({ error: "something was wrong" })
      );
    }
  }
};

export const getProcedureById = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.corporate.user_procedure,
    data
  );
  if (response && response.data && response.data.res === 1) {
    dispatch(
      slice.actions.setCurrentProcedure({ procedure: response.data.data })
    );
  } else {
    if (response && response.data.message) {
      dispatch(
        slice.actions.setProcedureError({ error: response.data.message })
      );
    } else {
      dispatch(
        slice.actions.setProcedureError({ error: "something was wrong" })
      );
    }
  }
};

const handleResponseCurrentProcedure = (response) => async (dispatch) => {
  dispatch(slice.actions.setProcedureError({ error: null }));
  if (response && response.data) {
    if (response.data.res === 1) {
      dispatch(
        slice.actions.setCurrentProcedure({ procedure: response.data.data })
      );
    } else {
      dispatch(
        slice.actions.setProcedureError({ error: response.data.message })
      );
    }
  } else {
    dispatch(slice.actions.setProcedureError({ error: "something was wrong" }));
  }
};

export const clearCurrentProcedure = () => async (dispatch) => {
  dispatch(slice.actions.clearCurrentProcedure());
};
export const addRequirementFile = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.corporate.user_procedure,
    data
  );
  dispatch(handleResponseCurrentProcedure(response));
};
export const deleteCurrentProcedure = (data) => async (dispatch) => {
  var response = await axios.post(arkadu.urls.v2.corporate.user_procedure, {
    action: "delete",
    ...data,
  });
  dispatch(
    slice.actions.setProcedureState({
      currentUserProcedure: null,
      cleared: true,
    })
  );
};
export const deleteRequirement = (data) => async (dispatch) => {
  dispatch(slice.actions.deleteRequirement(data));
  var response = await axios.post(arkadu.urls.v2.corporate.user_procedure, {
    action: "delete_requirement",
    ...data,
  });
  dispatch(handleResponseCurrentProcedure(response));
};
export const setCurrentProcedure = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentProcedure({ procedure: data }));
};
export const setCurrentDepartment = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentDepartment({ department: data }));
};
export const setCurrentPage = (data) => async (dispatch) => {
  dispatch(slice.actions.setCurrentPage(data));
};

export const updateFilters = (data) => async (dispatch) => {
  dispatch(slice.actions.updateFilters(data));
};
export const updateCurrentProcedure = (data) => async (dispatch) => {
  var response = await axios.post(
    arkadu.urls.v2.corporate.user_procedure,
    data
  );

  if (response && response.data) {
    if (response.data.res === 1) {
      dispatch(
        slice.actions.updateProcedure({ procedure: response.data.data })
      );
      dispatch(
        slice.actions.setProcedureState({
          currentUserProcedure: response.data.data,
        })
      );
    } else {
      dispatch(
        slice.actions.setProcedureError({ error: response.data.message })
      );
    }
  } else {
    dispatch(
      slice.actions.setProcedureError({ error: "something was wrong linking" })
    );
    setTimeout(
      () => dispatch(slice.actions.setProcedureError({ error: null })),
      50000
    );
  }
};
