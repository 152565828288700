//@ts-check
import {
  MonetizationOnRounded,
  NavigateNextRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Grid,
  Hidden,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import GroupButtonMenu from "src/components/custom/GroupButtonMenu";
import Dialogs from "src/components/Dialogs";
import TextInputSearch from "src/components/TextInputSearch";
import { media_url } from "src/coreConfig";
import PaymentAccounts from "./arkadu/accounts/parts/PaymentAccounts";
import ExchangeValues from "./arkadu/module/ExchangeValues";

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  appLogo: {
    // @ts-ignore theme.spacing
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
  baseHeaderActions: {
    "& .MuiButton-label": {
      fontSize: "0.70rem",
    },
  },
}));

const viewStates = {
  viewAccounts: "VIEW_PAYMENT_ACCOUNTS",
};

const BaseHeader = ({
  title,
  subTitle,
  subHeader,
  className,
  actionsMenu,
  searchProps,
  mainAction,
  currentCustomer,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  const routeParts = location.pathname.split("/");

  const [headerState, setHeaderState] = useState({
    currentActionMenu: null,
    currentTargetElement: null,
    currentElementId: null,
    state: null,
  });
  let linkTo = "";

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      {headerState.state && (
        <Dialogs
          value={headerState.state}
          onClose={() => setHeaderState({ ...headerState, state: null })}
          dialogs={[
            {
              value: viewStates.viewAccounts,
              title: t("Cuentas para pagos"),
              body: <PaymentAccounts />,
              actions: [
                {
                  onClick: () =>
                    setHeaderState({ ...headerState, state: null }),
                  label: t("Ok"),
                },
              ],
            },
          ]}
        />
      )}
      <Grid item xs={12}>
        {currentCustomer && currentCustomer.logo ? (
          <Grid container sx={{ display: "flex", flexDirection: "column" }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Avatar
                  src={media_url(currentCustomer.logo)}
                  variant="circular"
                  className={classes.appLogo}
                />
              </Grid>
              <Grid item>
                {typeof title === "string" ? (
                  <Typography variant="h4" color="textPrimary">
                    {title.toUpperCase()}
                  </Typography>
                ) : (
                  <>{title}</>
                )}
                {typeof subTitle === "string" ? (
                  <Typography variant="h5">{subTitle}</Typography>
                ) : (
                  <>{subTitle}</>
                )}
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Breadcrumbs
                separator={<NavigateNextRounded fontSize="small" />}
                aria-label="breadcrumb"
              >
                {routeParts &&
                  routeParts.map((r, k) => {
                    if (!r) {
                      return null;
                    }
                    // if (k === routeParts.length - 1) {
                    return (
                      <Typography
                        key={`${r}-link`}
                        color="textPrimary"
                        variant="caption"
                      >
                        {t(r)}
                      </Typography>
                    );
                    // }
                    // linkTo += `/${r}`;

                    // return (
                    //   <Link
                    //     variant="body1"
                    //     key={`${r}-link`}
                    //     color="inherit"
                    //     to={`${linkTo}`}
                    //     // component={}
                    //   >
                    //     {t(r)}
                    //   </Link>
                    // );
                  })}
              </Breadcrumbs>
            </Hidden>
            <Grid item sx={{ paddingLeft: "30px", maxWidth: "600px" }}>
              <ExchangeValues mode="banner" />
            </Grid>
          </Grid>
        ) : (
          title && (
            <Typography variant="h4" color="textPrimary">
              {title.toUpperCase()}
            </Typography>
          )
        )}
        {currentCustomer && currentCustomer.accounts && (
          <Grid item xs={12} sx={{ textAlign: "right", paddingBottom: "14px" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setHeaderState({
                  ...headerState,
                  state: viewStates.viewAccounts,
                });
              }}
              startIcon={<MonetizationOnRounded />}
            >
              {t("�Donde Pagar?")}
            </Button>
          </Grid>
        )}
        {subHeader && subHeader}
        {actionsMenu && (
          <GroupButtonMenu label={t("Acciones")} items={actionsMenu} />
        )}
      </Grid>
      {mainAction && (
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            startIcon={mainAction.icon || mainAction.startIcon}
            onClick={() => mainAction.onClick()}
          >
            {mainAction.label}
          </Button>
        </Grid>
      )}
      {searchProps && (
        <Grid item>
          <TextInputSearch {...searchProps} />
        </Grid>
      )}
    </Grid>
  );
};

BaseHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  actionsMenu: GroupButtonMenu.propTypes.items,
  mainAction: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.node,
    onClick: PropTypes.func,
  }),
  searchProps: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
  }),

  minWidth: PropTypes.bool,
};

BaseHeader.defaultProps = {
  title: "",
  subTitle: "",
  subHeader: "",
  actionsMenu: [],
  mainAction: null,
  searchProps: null,

  minWidth: false,
};

export default BaseHeader;
