import _ from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router-dom";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import arkadu from "src/coreConfig";
import animationData from "src/lotties/arkadu_city.json";
import { clearCurrentCustomer, setCustomerByName } from "src/slices/customers";
import { resetGlobals } from "src/slices/global";
import { saveSettings } from "src/slices/settings";
import {
  clearTaxesError,
  setCurrentLicense,
  setUpdatedTaxes,
} from "src/slices/taxes";
import { loadUserFromStorage } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import { apiGet } from "src/utils/axios";
import NotAuthorizedView from "src/views/errors/NotAuthorizedView";
import useSWR from "swr";
import LoadingScreen from "./LoadingScreen";
import LottieAnimation from "./LottieAnimation";

const useUser = () => {
  const { data, error, isValidating } = useSWR(
    arkadu.urls.user.get_profile,
    apiGet
  );
  return {
    remoteUser: data,
    isLoading: !error && !data,
    isError: error,
  };
};

const AuthGuard = ({ children }) => {
  const { user, isAuthenticated, loaded } = useSelector((state) => state.users);
  // const { remoteUser, isLoading, isError } = useUser();
  // const [userErrorCount, setUserErrorCount] = useState(0);
  const { settings } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const { currentCustomer, customers, customerSections, customersList } =
    useSelector((state) => state.customers);

  const { t } = useTranslation();
  const { motorError } = useSelector((state) => state.motor);
  const { taxesError } = useSelector((state) => state.taxes);
  const { customerName } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const [lastCustomer, setLastCustomer] = useState(null);
  const [membership, setMembership] = useState(false);
  const [checkingPerms, setCheckingPerms] = useState(true);
  const [moduleEnabled, setModuleEnabled] = useState(2);
  const [ehCoverage, setEhCoverage] = useState(false);

  useEffect(() => {
    if (customerSections) {
      if (customerSections.length === 0) {
        setModuleEnabled(0);
      } else {
        var e = false;
        var _clearPath = location.pathname.replace(/\//g, "");

        var _path = _clearPath;
        if (currentCustomer) {
          var _path = _clearPath.replace(currentCustomer.shortname, "");
        }
        var _path_enabled = false;
        var enabled_paths = ["monitorappointments", "app", "AdminDashboard"];
        enabled_paths.map((_p) => {
          if (_path.startsWith(_p)) {
            _path_enabled = true;
          }
        });
        // if (enabled_paths.includes(_path)) {
        //   _path_enabled = true;
        // }

        if (
          currentCustomer &&
          (_clearPath === currentCustomer.shortname || _path_enabled)
        ) {
          e = true;
        } else {
          _.forEach(customerSections, (_s) => {
            if (
              currentCustomer &&
              (location.pathname.includes(
                `/${currentCustomer.shortname}${_s.href}`
              ) ||
                location.pathname.includes(
                  `/${currentCustomer.shortname}/admin/`
                ))
            ) {
              e = true;
            }
          });
        }
        setModuleEnabled(e);
      }
    }
  }, [customerSections]);

  useEffect(() => {
    var hostname = window.location.hostname;

    if (hostname !== "arkadu.com" && !currentCustomer && customersList) {
      // console.log(hostname);
      var _customer = _.find(
        customersList,
        (_c) => _c.custom_domain === hostname
      );

      if (_customer) {
        dispatch(setCustomerByName({ shortname: _customer.shortname }));
      }
    }
  }, [location, customersList, currentCustomer]);

  useEffect(() => {
    if (!currentCustomer && customerName) {
      dispatch(setCustomerByName({ shortname: customerName }));
    }
  }, [customerName, currentCustomer]);

  useEffect(() => {
    if (location.pathname.startsWith("/app")) {
      dispatch(saveSettings({ ...settings, adminMode: false }));
      dispatch(clearCurrentCustomer({ licenses: null }));
      dispatch(setUpdatedTaxes({ licenses: null }));
      dispatch(setCurrentLicense(null));
      dispatch(resetGlobals());
    }

    if (location.pathname.endsWith("/login")) {
      handleAuthRedirect();
    }
  }, [location.pathname]);

  // Check urban cleaning address subscriptions
  useEffect(() => {
    if (user && user.addresses) {
      var eh_coverage = _.find(user.addresses, (a) => a.municipality_id === 81);
      if (eh_coverage && !eh_coverage.urban_cleaning_discard) {
        setEhCoverage(true);
      }
    }
    if (!user) {
      dispatch(loadUserFromStorage());
    }
  }, [user]);

  useEffect(() => {
    if (motorError) {
      enqueueSnackbar(motorError, { variant: "error" });
    }
  }, [motorError]);
  useEffect(() => {
    if (taxesError) {
      enqueueSnackbar(taxesError, { variant: "error" });
      dispatch(clearTaxesError());
    }
  }, [taxesError]);

  const checkUserMembership = useCallback(() => {
    if (!currentCustomer) {
      return;
    }

    if (!user) {
      return;
    }

    if (!user.member_set) {
      return false;
    }
    user.member_set.map((m, k) => {
      if (m.customer_id === currentCustomer.id) {
        setMembership(true);
        setCheckingPerms(false);
        return true;
      }
      if (k === user.member_set.length - 1) {
        // setMembership(false);
        setCheckingPerms(false);
        return false;
      }
    });

    setCheckingPerms(false);
  }, [currentCustomer, user, checkingPerms, membership]);

  useEffect(() => {
    if (currentCustomer && currentCustomer.id !== lastCustomer) {
      //dispatch(saveSettings({ ...settings, adminMode: false }));
      setLastCustomer(currentCustomer.id);
    } else {
      setLastCustomer(null);
      //dispatch(saveSettings({ ...settings, adminMode: false }));
    }
  }, [currentCustomer]);

  const handleAuthRedirect = () => {
    var url = `https://${arkadu.hostname}/auth`;
    if (customer_id) {
      url += `?customer_id=${customer_id}&client_id=${arkadu.client_id}`;
    }
    // redirect to auth
    window.location.replace(url, "_self");
  };

  useEffect(() => {
    if (!isAuthenticated && loaded) {
      // handleAuthRedirect();
    }
  }, [isAuthenticated, loaded]);

  // useEffect(() => {
  //   if ((!loaded || !user) && !logout) {
  //     dispatch(loadUserFromStorage());
  //   }
  // }, [loaded, user, logout]);

  // useEffect(() => {
  //   if (isError && !remoteUser) {
  //     if (userErrorCount >= 1) {
  //       dispatch(logout());
  //     } else {
  //       var t = localStorage.getItem("item");
  //       if (!t) {
  //         setUserErrorCount(5);
  //       } else {
  //         setUserErrorCount(userErrorCount + 1);
  //       }
  //     }
  //   }
  //   if (remoteUser) {
  //     dispatch(setUser(remoteUser));
  //   }
  // }, [remoteUser, isError]);

  useEffect(() => {
    if (!isAuthenticated && loaded) {
      if (!window.location.pathname.endsWith("login"))
        localStorage.setItem("next", window.location.pathname);
      // history.push("/login");
    }
  }, [isAuthenticated, loaded]);

  useEffect(() => {
    if (!location.pathname.includes("/admin")) {
      setCheckingPerms(false);
      return;
    }

    if (user && currentCustomer && location.pathname.includes("/admin")) {
      checkUserMembership();
    }
  }, [currentCustomer, user, location.pathname]);

  if (customerName && !currentCustomer) {
    return <LottieAnimation animationData={animationData} size="60%" />;
  }

  if (moduleEnabled === 2) {
    return <LoadingScreen message={t("comprobando m�dulo")} />;
  }

  if (currentCustomer && !moduleEnabled) {
    return (
      <NotAuthorizedView
        message={t("El cliente no tiene habilitado este m�dulo")}
      />
    );
  }

  if (checkingPerms || !loaded) {
    return <LoadingScreen message={t("verificando permisos")} />;
  }

  if (!isAuthenticated && loaded) {
    return <Redirect to={customerName ? `/${customerName}/login` : "/login"} />;
  }

  if (
    user &&
    !user.is_completed &&
    location.pathname !== "/app/account/registration"
  ) {
    return <Redirect to="/app/account/registration" />;
  }

  // if (ehCoverage && location.pathname !== '/InversionesEH/urban_cleaning/') {
  //   return <Redirect to="/InversionesEH/urban_cleaning/" />;
  // }

  if (location.pathname.includes("/admin") && !membership) {
    if (!location.pathname.includes("/corporate/admin/")) {
      var newPath = location.pathname.replace("/admin", "");
      history.push(newPath);
      return <NotAuthorizedView />;
    }
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
